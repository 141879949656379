import { useStyles } from '@archipro-design/aria';
import * as S from './NotFound.style';
import TrendingCategories from '../trending-categories/TrendingCategories';
import type { TrendingCategoriesData } from '@modules/error/type';
import DirectoryLinks from '@modules/error/component/directory-links/DirectoryLinks';
import type { StatusNumbersData } from '@modules/error/type';

export interface NotFoundProps {
    trendingCategories: TrendingCategoriesData;
    statsNumbers: StatusNumbersData;
}

const NotFound = ({ trendingCategories, statsNumbers }: NotFoundProps) => {
    const { css } = useStyles();

    return (
        <div className={css(S.BaseStyle)}>
            <div className={css(S.MainTextStyle)}>
                {`Sorry the page you were looking for couldn't be found.`}
            </div>
            <div className={css(S.SubTextStyle)}>
                {`Can't find what you are looking for? Explore one of our trending
                categories:`}
            </div>
            <TrendingCategories trendingCategories={trendingCategories} />
            <div className={css(S.JoinTextStyle)}>or browse:</div>
            <DirectoryLinks statsNumbers={statsNumbers} />
        </div>
    );
};

export default NotFound;
