import { useStyles } from '@archipro-design/aria';
import * as S from './DirectoryLink.style';
interface DirectoryLinksProps {
    icon: JSX.Element;
    title: string;
    href: string;
}

const DirectoryLink = ({ icon, title, href }: DirectoryLinksProps) => {
    const { css } = useStyles();

    return (
        <a className={css(S.BaseStyle)} href={href}>
            {icon}
            <div className={css(S.BaseLinkTitle)}>{title}</div>
        </a>
    );
};

export default DirectoryLink;
