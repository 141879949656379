import { Flex, useStyles } from '@archipro-design/aria';
import * as S from './DirectoryLinks.style';
import DirectoryLink from '@modules/error/component/directory-link/DirectoryLink';
import {
    Professional,
    Product,
    Project,
    Article,
} from '@archipro-design/icons';
import type { StatusNumbersData } from '@modules/error/type';

interface DirectoryLinksProps {
    statsNumbers: StatusNumbersData;
}

const DirectoryLinks = ({ statsNumbers }: DirectoryLinksProps) => {
    const { css } = useStyles();

    const formatTitle = (count: number, name: string): string => {
        let formattedNumber = 0;
        if (count > 10000) {
            formattedNumber = Math.floor(count / 1000) * 1000;
        } else if (count > 1000) {
            formattedNumber = Math.floor(count / 100) * 100;
        } else {
            formattedNumber = Math.floor(count / 10) * 10;
        }
        return `${formattedNumber.toLocaleString()}+ ${name}`;
    };

    return (
        <Flex className={css(S.BaseStyle)}>
            <DirectoryLink
                icon={<Project className={css(S.IconBase)} />}
                title={formatTitle(statsNumbers.ProjectCount, 'projects')}
                href={'/projects'}
            />
            <DirectoryLink
                icon={<Product className={css(S.IconBase)} />}
                title={formatTitle(statsNumbers.ProductCount, 'products')}
                href={'/products'}
            />
            <DirectoryLink
                icon={<Professional className={css(S.IconBase)} />}
                title={formatTitle(
                    statsNumbers.ProfessionalCount,
                    'professionals'
                )}
                href={'/professionals'}
            />
            <DirectoryLink
                icon={<Article className={css(S.IconBase)} />}
                title={'Latest trends'}
                href={'/articles'}
            />
        </Flex>
    );
};

export default DirectoryLinks;
