import { useStyles } from '@archipro-design/aria';
import * as S from './TrendingCategory.style';
import type { TrendingCategoryData } from '@modules/error/type';

interface TrendingCategoryProps {
    trendingCategory: TrendingCategoryData;
}

const TrendingCategory = ({ trendingCategory }: TrendingCategoryProps) => {
    const { css } = useStyles();

    return (
        <a className={css(S.BaseStyle)} href={trendingCategory.Href}>
            {' '}
            {trendingCategory.Title}
        </a>
    );
};

export default TrendingCategory;
