import type { StyleRule } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';

export const BaseStyle: StyleRule = ({ theme }) => ({
    ...theme.siteVariables.textStyles.Display.Roman55,
    backgroundColor: theme.siteVariables.colors.charcoal[250],
    padding: pxToRem(16),
    marginRight: pxToRem(8),
    color: '#FFF',
    textTransform: 'capitalize',
    letterSpacing: pxToRem(1.1),
    fontWeight: 400,
    fontSize: pxToRem(16),
    lineHeight: '100%',
    [theme.screen.min.tablet]: {
        fontSize: pxToRem(24),
        padding: pxToRem(16),
        lineHeight: pxToRem(26.4),
        letterSpacing: pxToRem(1.2),
        marginRight: pxToRem(24),
    },
});
