import type { StyleRule } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';

export const BaseStyle: StyleRule = ({ theme }) => ({
    ...theme.siteVariables.textStyles.Display.Roman55,
    color: theme.siteVariables.colors.charcoal[250],
    fontSize: pxToRem(16),
    lineHeight: '100%',
    display: 'flex',
    flexDirection: 'row',
    [theme.screen.min.tablet]: {
        fontSize: pxToRem(20),
        lineHeight: pxToRem(26),
    },
});

export const BaseLinkTitle: StyleRule = () => ({
    display: 'inline',
    marginLeft: pxToRem(10),
    letterSpacing: '0.9rem',
    whiteSpace: 'nowrap',
});
