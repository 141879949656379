import type { StyleRule } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';

export const BaseStyle: StyleRule = ({ theme }) => ({
    color: theme.siteVariables.colors.charcoal[250],
    paddingTop: pxToRem(27),
    marginBottom: pxToRem(82),
    flexFlow: 'wrap',
    columnGap: pxToRem(20),
    rowGap: pxToRem(25),
    [theme.screen.min.tablet]: {
        paddingTop: pxToRem(22),
        marginBottom: pxToRem(240),
    },
});

export const IconBase: StyleRule = ({ theme }) => ({
    height: pxToRem(28),
    width: pxToRem(30),
    marginTop: '-5rem',
    [theme.screen.min.tablet]: {
        marginTop: '-2rem',
    },
});
