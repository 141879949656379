import { Flex, useStyles } from '@archipro-design/aria';
import type { TrendingCategoriesData } from '@modules/error/type';
import TrendingCategory from '@modules/error/component/trending-category/TrendingCategory';
import * as S from './TrendingCategories.style';

interface TrendingCategoriesProps {
    trendingCategories: TrendingCategoriesData;
}

const TrendingCategories = ({
    trendingCategories,
}: TrendingCategoriesProps) => {
    const { css } = useStyles();

    return (
        <Flex className={css(S.BaseStyle)}>
            {trendingCategories.map((trendingCategory, i) => {
                return (
                    <TrendingCategory
                        key={i}
                        trendingCategory={trendingCategory}
                    />
                );
            })}
        </Flex>
    );
};

export default TrendingCategories;
